

































































































































































































































































































import useAuthGroupSelect from "@/use/authGroupSelect";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  setup(props, { root, emit }) {
    const {
      eventPanelItems,
      participantItems,
      lecturersItems,
      companiesItems,
      abstractsItems,
      posterSessionItems,
      surveysItems,
      printItems,
      reportsItems,
      infoItems,
      eventConfigItems,
      registrationModuleItems,
      accomodationModuleItems,
      additionalServicesModuleItems,
      abstractsModuleItems,
      partnersModuleItems,
      eventProgramModuleItems,
      posterSessionModuleItems,
      surveysModuleItems,
      autoMessagesModuleItems,
      companiesModuleItems,
      receptionModuleItems,
      siemensItems,
    } = useAuthGroupSelect({ root });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      lecturerPhoto: "",
      oldImage: null as null | { id: string },
      empty: false,
      table: false,
      loaded: false,
      items: [],
    });

    const model = reactive<{
      data: any;
    }>({
      data: {
        name: "",
        eventPanel: [] as "alerts"[],
        participants: [] as ("participantsList" | "participantsManagement")[],
        lecturers: [] as ("lecturersList" | "lecturersManagement")[],
        companies: [] as ("companiesList" | "companiesManagement")[],
        abstracts: [] as ("abstractsList" | "abstractsManagement")[],
        posterSession: [] as "posterSessionList"[],
        surveys: [] as "surveysList"[],
        print: [] as ("printList" | "printManagement")[],
        reports: [] as "reportsManagement"[],
        info: [] as "view"[],
        eventConfig: [] as (
          | "eventData"
          | "graphicOptions"
          | "participantGroups"
          | "discounts"
          | "messages"
        )[],
        registrationModule: [] as (
          | "moduleData"
          | "moduleDegree"
          | "moduleFee"
        )[],
        accomodationModule: [] as ("moduleData" | "moduleHotels")[],
        additionalServicesModule: [] as (
          | "moduleData"
          | "moduleServices"
          | "moduleForms"
        )[],
        abstractsModule: [] as (
          | "moduleData"
          | "moduleSessions"
          | "modulePublications"
        )[],
        partnersModule: [] as "view"[],
        eventProgramModule: [] as ("moduleProgram" | "moduleLocalization")[],
        posterSessionModule: [] as "view"[],
        surveysModule: [] as "view"[],
        autoMessagesModule: [] as "view"[],
        companiesModule: [] as (
          | "moduleData"
          | "moduleCodes"
          | "moduleFees"
          | "moduleHotels"
          | "moduleAdditionalServices"
        )[],
        receptionModule: [] as (
          | "moduleIdentities"
          | "moduleCertificates"
          | "moduleTerminals"
          | "moduleKiosks"
        )[],
        siemens: [] as "siemensReports"[],
      },
    });

    //  Start fetch single user group data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`user/auth/group/${root.$route.params.id}`)
        .then(({ data: { group } }) => {
          model.data.name = group.name || undefined;
          if (group.eventPanel.alerts) model.data.eventPanel.push("alerts");
          if (group.participants.participantsList)
            model.data.participants.push("participantsList");
          if (group.participants.participantsManagement)
            model.data.participants.push("participantsManagement");
          if (group.lecturers.lecturersList)
            model.data.lecturers.push("lecturersList");
          if (group.lecturers.lecturersManagement)
            model.data.lecturers.push("lecturersManagement");
          if (group.companies.companiesList)
            model.data.companies.push("companiesList");
          if (group.companies.companiesManagement)
            model.data.companies.push("companiesManagement");
          if (group.abstracts.abstractsList)
            model.data.abstracts.push("abstractsList");
          if (group.abstracts.abstractsManagement)
            model.data.abstracts.push("abstractsManagement");
          if (group.posterSession.posterSessionList)
            model.data.posterSession.push("posterSessionList");
          if (group.surveys.surveysList) model.data.surveys.push("surveysList");
          if (group.print.printList) model.data.print.push("printList");
          if (group.print.printManagement)
            model.data.print.push("printManagement");
          if (group.reports.reportsManagement)
            model.data.reports.push("reportsManagement");
          if (group.info.view) model.data.info.push("view");
          if (group.eventConfig.eventData)
            model.data.eventConfig.push("eventData");
          if (group.eventConfig.graphicOptions)
            model.data.eventConfig.push("graphicOptions");
          if (group.eventConfig.participantGroups)
            model.data.eventConfig.push("participantGroups");
          if (group.eventConfig.discounts)
            model.data.eventConfig.push("discounts");
          if (group.eventConfig.messages)
            model.data.eventConfig.push("messages");
          if (group.eventModules.registrationModule.moduleData)
            model.data.registrationModule.push("moduleData");
          if (group.eventModules.registrationModule.moduleDegree)
            model.data.registrationModule.push("moduleDegree");
          if (group.eventModules.registrationModule.moduleFee)
            model.data.registrationModule.push("moduleFee");
          if (group.eventModules.accomodationModule.moduleData)
            model.data.accomodationModule.push("moduleData");
          if (group.eventModules.accomodationModule.moduleHotels)
            model.data.accomodationModule.push("moduleHotels");
          if (group.eventModules.additionalServicesModule.moduleData)
            model.data.additionalServicesModule.push("moduleData");
          if (group.eventModules.additionalServicesModule.moduleServices)
            model.data.additionalServicesModule.push("moduleServices");
          if (group.eventModules.additionalServicesModule.moduleForms)
            model.data.additionalServicesModule.push("moduleForms");
          if (group.eventModules.abstractsModule.moduleData)
            model.data.abstractsModule.push("moduleData");
          if (group.eventModules.abstractsModule.moduleSessions)
            model.data.abstractsModule.push("moduleSessions");
          if (group.eventModules.abstractsModule.modulePublications)
            model.data.abstractsModule.push("modulePublications");
          if (group.eventModules.partnersModule.view)
            model.data.partnersModule.push("view");
          if (group.eventModules.eventProgramModule.moduleProgram)
            model.data.eventProgramModule.push("moduleProgram");
          if (group.eventModules.eventProgramModule.moduleLocalization)
            model.data.eventProgramModule.push("moduleLocalization");
          if (group.eventModules.posterSessionModule.view)
            model.data.posterSessionModule.push("view");
          if (group.eventModules.surveysModule.view)
            model.data.surveysModule.push("view");
          if (group.eventModules.autoMessagesModule.view)
            model.data.autoMessagesModule.push("view");
          if (group.eventModules.companiesModule.moduleData)
            model.data.companiesModule.push("moduleData");
          if (group.eventModules.companiesModule.moduleCodes)
            model.data.companiesModule.push("moduleCodes");
          if (group.eventModules.companiesModule.moduleFees)
            model.data.companiesModule.push("moduleFees");
          if (group.eventModules.companiesModule.moduleHotels)
            model.data.companiesModule.push("moduleHotels");
          if (group.eventModules.companiesModule.moduleAdditionalServices)
            model.data.companiesModule.push("moduleAdditionalServices");
          if (group.eventModules.receptionModule.moduleIdentities)
            model.data.receptionModule.push("moduleIdentities");
          if (group.eventModules.receptionModule.moduleCertificates)
            model.data.receptionModule.push("moduleCertificates");
          if (group.eventModules.receptionModule.moduleTerminals)
            model.data.receptionModule.push("moduleTerminals");
          if (group.eventModules.receptionModule.moduleKiosks)
            model.data.receptionModule.push("moduleKiosks");
          if (group.siemens.reports) model.data.siemens.push("siemensReports");
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single user group data

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        eventPanel: {
          alerts: model.data.eventPanel?.includes("alerts") || false,
        },
        participants: {
          participantsList:
            model.data.participants?.includes("participantsList") || false,
          participantsManagement:
            model.data.participants?.includes("participantsManagement") ||
            false,
        },
        lecturers: {
          lecturersList:
            model.data.lecturers?.includes("lecturersList") || false,
          lecturersManagement:
            model.data.lecturers?.includes("lecturersManagement") || false,
        },
        companies: {
          companiesList:
            model.data.companies?.includes("companiesList") || false,
          companiesManagement:
            model.data.companies?.includes("companiesManagement") || false,
        },
        abstracts: {
          abstractsList:
            model.data.abstracts?.includes("abstractsList") || false,
          abstractsManagement:
            model.data.abstracts?.includes("abstractsManagement") || false,
        },
        posterSession: {
          posterSessionList:
            model.data.posterSession?.includes("posterSessionList") || false,
        },
        surveys: {
          surveysList: model.data.surveys?.includes("surveysList") || false,
        },
        print: {
          printList: model.data.print?.includes("printList") || false,
          printManagement:
            model.data.print?.includes("printManagement") || false,
        },
        reports: {
          reportsManagement:
            model.data.reports?.includes("reportsManagement") || false,
        },
        info: {
          view: model.data.info?.includes("view") || false,
        },
        eventConfig: {
          eventData: model.data.eventConfig?.includes("eventData") || false,
          graphicOptions:
            model.data.eventConfig?.includes("graphicOptions") || false,
          participantGroups:
            model.data.eventConfig?.includes("participantGroups") || false,
          discounts: model.data.eventConfig?.includes("discounts") || false,
          messages: model.data.eventConfig?.includes("messages") || false,
        },
        eventModules: {
          registrationModule: {
            moduleData:
              model.data.registrationModule?.includes("moduleData") || false,
            moduleDegree:
              model.data.registrationModule?.includes("moduleDegree") || false,
            moduleFee:
              model.data.registrationModule?.includes("moduleFee") || false,
          },
          accomodationModule: {
            moduleData:
              model.data.accomodationModule?.includes("moduleData") || false,
            moduleHotels:
              model.data.accomodationModule?.includes("moduleHotels") || false,
          },
          additionalServicesModule: {
            moduleData:
              model.data.additionalServicesModule?.includes("moduleData") ||
              false,
            moduleServices:
              model.data.additionalServicesModule?.includes("moduleServices") ||
              false,
            moduleForms:
              model.data.additionalServicesModule?.includes("moduleForms") ||
              false,
          },
          abstractsModule: {
            moduleData:
              model.data.abstractsModule?.includes("moduleData") || false,
            moduleSessions:
              model.data.abstractsModule?.includes("moduleSessions") || false,
            modulePublications:
              model.data.abstractsModule?.includes("modulePublications") ||
              false,
          },
          partnersModule: {
            view: model.data.partnersModule?.includes("view") || false,
          },
          eventProgramModule: {
            moduleProgram:
              model.data.eventProgramModule?.includes("moduleProgram") || false,
            moduleLocalization:
              model.data.eventProgramModule?.includes("moduleLocalization") ||
              false,
          },
          posterSessionModule: {
            view: model.data.posterSessionModule?.includes("view") || false,
          },
          surveysModule: {
            view: model.data.surveyModule?.includes("view") || false,
          },
          autoMessagesModule: {
            view: model.data.autoMessagesModule?.includes("view") || false,
          },
          companiesModule: {
            moduleData:
              model.data.companiesModule?.includes("moduleData") || false,
            moduleCodes:
              model.data.companiesModule?.includes("moduleCodes") || false,
            moduleFees:
              model.data.companiesModule?.includes("moduleFees") || false,
            moduleHotels:
              model.data.companiesModule?.includes("moduleHotels") || false,
            moduleAdditionalServices:
              model.data.companiesModule?.includes(
                "moduleAdditionalServices"
              ) || false,
          },
          receptionModule: {
            moduleIdentities:
              model.data.receptionModule?.includes("moduleIdentities") || false,
            moduleCertificates:
              model.data.receptionModule?.includes("moduleCertificates") ||
              false,
            moduleTerminals:
              model.data.receptionModule?.includes("moduleTerminals") || false,
            moduleKiosks:
              model.data.receptionModule?.includes("moduleKiosks") || false,
          },
        },
        siemens: {
          reports: model.data.siemens?.includes("siemensReports") || false,
        },
      };

      state.loading = true;

      axiosInstance
        .put(`user/auth/group/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          model.data.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Grupa już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      eventPanelItems,
      participantItems,
      lecturersItems,
      companiesItems,
      abstractsItems,
      posterSessionItems,
      surveysItems,
      printItems,
      reportsItems,
      infoItems,
      eventConfigItems,
      registrationModuleItems,
      accomodationModuleItems,
      additionalServicesModuleItems,
      abstractsModuleItems,
      partnersModuleItems,
      eventProgramModuleItems,
      posterSessionModuleItems,
      surveysModuleItems,
      autoMessagesModuleItems,
      companiesModuleItems,
      receptionModuleItems,
      siemensItems,
    };
  },
});
